<template>
  <div>
    <Loading v-show="loading"></Loading>
    <v-form
      ref="form"
      class="multi-col-validation"
    >
      <v-row>
        <v-col
          cols="12"
        >
          <label>期間</label>
          <div>
            <date-range-picker
              ref="datePicker"
              :search-btn-show="false"
              :shortcuts-show="false"
            ></date-range-picker>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <label>店舗名</label>
          <v-select
            item-text="siteName"
            item-value="siteId"
            :items="sitelist"
            label="Select SC"
            :rules="[(v) => !!v || 'required']"
            outlined
            @change="selectSc"
          ></v-select>
        </v-col>
        <v-col cols="12">
          <v-btn
            style="text-transform: none"
            color="primary"
            @click="download"
          >
            Download
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import DateRangePicker from '@/views/forms/form-elements/datepicker/DateRangePicker.vue'
import { constant } from '@core/utils/constant'
import crypto from 'crypto-js'
import store from '@/store'
import exportCsvStoreModule from './exportCsvStoreModule'
import { onUnmounted } from '@vue/composition-api'
import Loading from '@/views/components/load/Loading.vue'
import { date2String } from '@core/utils'

const USER_APP_STORE_MODULE_NAME = 'app-exportCsv'
const currentDate = new Date()

export default {
  components: {
    DateRangePicker,
    Loading,
  },
  mounted() {
    this.$dateRangePickerDisplayFlag = false
    this.$selectScDisplayFlag = true
    this.$selectDomesticScDisplayFlag = false
    this.$domesticDatePickerDisplayFlag = false
    this.$datePickerDisplayFlag = false
  },
  methods: {
    selectSc(siteId) {
      this.selectedSiteId = siteId
    },
    csvData2Str(tempData) {
      let str = ''
      const lenElement = Object.keys(tempData).length
      for (let i = 0; i < lenElement; i += 1) {
        str = `${str}${tempData[i][0]},`
        const lenTempData = Object.keys(tempData[i][1]).length
        for (let j = 0; j < lenTempData; j += 1) {
          if (j === lenTempData - 1) {
            str = `${str}${tempData[i][1][j]}\n`
          } else {
            str = `${str}${tempData[i][1][j]},`
          }
        }
      }

      return str
    },
    download() {
      const isFormValid = this.$refs.form.validate()

      if (!isFormValid) return

      const searchStartDate = date2String(this.$refs.datePicker.dateRange[0])
      const searchEndDate = date2String(this.$refs.datePicker.dateRange[1])
      console.log(this.selectedSiteId)
      const siteId = this.selectedSiteId
      this.loading = true
      store
        .dispatch('app-exportCsv/fetchData', {
          params: {
            siteId,
            searchStartDate,
            searchEndDate,
          },
        })
        .then(response => {
          this.loading = false
          const csvData = {}
          csvData.data = response.data.result.data.data
          csvData.header1 = response.data.result.data.header1
          csvData.header2 = response.data.result.data.header2

          const tempData = []
          let header1Temp = []
          let header2Temp = []
          header1Temp = ['', csvData.header1]
          header2Temp = ['', csvData.header2]
          tempData.push(header1Temp)
          tempData.push(header2Temp)

          csvData.data.forEach(item => {
            const counts = []
            counts.push(item.counts)
            const temp = [item.time, counts]
            tempData.push(temp)
          })
          const context = this.csvData2Str(tempData)

          const blob = new Blob([context], { type: 'text/csv' }) // 配列に上記の文字列(str)を設定
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `Maintenance_Data_Export_${new Date().toLocaleString()}.csv`
          link.click()
        })
        .catch(error => {
          console.log(error)
          this.loading = false
          this.$router.push({ name: 'error-login' })
        })
    },
  },
  setup() {
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, exportCsvStoreModule)
    }

    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    let startDateString = sessionStorage.getItem('startDate')
    let endDateString = sessionStorage.getItem('endDate')

    if (startDateString === '') {
      startDateString = currentDate
      endDateString = currentDate
    }
    const initDateRange = {
      startDate: startDateString,
      endDate: endDateString,
    }

    // 拠点情報取得
    const ecryptedData = sessionStorage.getItem('siteList')

    // 復号する
    const decrypted = crypto.AES.decrypt(ecryptedData, constant.secret)
    const siteList = JSON.parse(decrypted.toString(crypto.enc.Utf8))
    console.log(siteList)
    const scList = []
    siteList.forEach(element => {
      if (element.id === 8 || element.id === 2 || element.id === 3 || element.id === 11 || element.id === 12 || element.id === 14 || element.id === 15 || element.id === 16) {
        const json = {
          siteName: element.name,
          siteId: element.id,
        }

        scList.push(json)
      }
    })

    return {
      initDateRange,
      sitelist: scList,
      siteId: '',
      siteName: '',
      serviceId: '',
      serviceName: '',
      loading: false,
      selectedSiteId: '',
      date2String,
    }
  },
}
</script>
